import Box from '../Box.jsx';
import Typography from '../Typography.jsx';
import Container from '../Container.jsx';
import asset from '@/utils/VaporUtil.js';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';

const PublicLayout = ({ children, ...properties }) => (
    <div {...properties}>
        <header className={'py-10'}>
            <Container className={'flex max-w-screen-2xl items-center justify-between'}>
                <Box as={'img'} src={asset('assets/images/logo/black.svg')} alt={'Capilex'} height={{ xs: 32, lg: 40 }} />

                <a href={'https://www.capilex.com'}>
                    <div className={'flex items-center gap-2'}>
                        <p>Terug naar website</p>
                        <ArrowUturnLeftIcon className={'aspect-square h-6'} />
                    </div>
                </a>
            </Container>
        </header>

        <main>{children}</main>

        <footer className={'bg-grey-150 py-10'}>
            <Container className={'flex max-w-screen-2xl items-center justify-between'}>
                <Typography>&copy; Capilex</Typography>

                <Typography>
                    Produced by&nbsp;
                    <a href={'https://www.digilution.agency'} target={'_blank'} rel='noreferrer'>
                        Digilution
                    </a>
                </Typography>
            </Container>
        </footer>
    </div>
);

export default PublicLayout;
